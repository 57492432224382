
import {Vue, Component, VModel, Prop} from 'vue-property-decorator'
import {pushDataLayer} from '@/utils/gtm.utils'

@Component
export default class SwitchInput extends Vue {
  @VModel({type: Boolean}) state!: boolean

  @Prop(Boolean)
  readonly disabled?: boolean

  @Prop(Boolean)
  readonly error?: boolean

  get bodyClasses () {
    return {'c-switch-input__body_active': this.state}
  }

  get classes () {
    return {
      'c-switch-input_disabled': this.disabled,
      'c-switch-input_error': this.error,
      'c-switch-input_active': this.state
    }
  }

  onClick (e: Event) {
    if (this.disabled) {
      return
    }

    this.state = !this.state
    pushDataLayer(e.target as HTMLElement, this.state ? 'c-switch-input' : 'c-switch-input_active')
  }
}
