import {getRandomArbitrary} from './random.utils'

export function pushDataLayer (
  element: HTMLElement,
  elementClasses: string,

): void {
  // @ts-ignore
  window.dataLayer = (window.dataLayer as any[]) || []

  // @ts-ignore
  window.dataLayer.push({
    event: 'gtm.click',
    gtm: {
      element,
      elementClasses,
      elementId: '',
      elementTarget: '',
      triggers: '',
      elementUrl: '',
      uniqueEventId: getRandomArbitrary(1000, 9999)
    }
  })
}
